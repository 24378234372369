<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="PurchaseOrderItems"
            calculate-widths
            class="mt-3 appic-table-light specification-table"
            dense
            disable-pagination
            disable-sort
            hide-default-header
            hide-default-footer
            item-key="index"
            single-select
        >
            <template v-slot:header="{ props: { headers } }">
                <tr>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct">{{ headers[0].text }}</th>
                    <th :class="'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left' + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? ' width-8-pct' : ' width-10-pct')">{{ headers[1].text }}</th>
                    <th :class="'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left' + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? ' width-10-pct' : ' width-12-pct')">{{ headers[2].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left">{{ headers[3].text }}</th>
                    <th :class="'grey lighten-3 py-0 pr-0 pl-1 font-sm text-left' + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? ' width-15-pct' : ' width-20-pct')">{{ headers[4].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct">{{ headers[5].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct">{{ headers[6].text  + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + getCurrency(Purchaseorder__Contract__currency_id) + ')' : '')}}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-6-pct">{{ headers[7].text + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + getCurrency(Purchaseorder__Contract__currency_id) + ')' : '') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[8].text + '(' + getCurrency(Purchaseorder__currency_id) + ')' }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[9].text + '(' + getCurrency(Purchaseorder__currency_id) + ')' }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ headers[10].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">{{ $t('message.actions') }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-8-pct" v-if="Purchaseorder__Contract__currency_id == Purchaseorder__currency_id">{{ headers[8].text }}</th>
                    <th class="grey lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct" v-if="Purchaseorder__Contract__currency_id == Purchaseorder__currency_id">{{ $t('message.actions') }}</th>
                </tr>
            </template>
            <template v-slot:item.lineNumber="{ item }">
                <div class="text-center mt-2">{{ item.lineNumber }}</div>
            </template>
            <template v-slot:item.Salesitem.certification_id="{ item }">
                <v-select
                    :id="item.index + 'certification'"
                    :items="allCertifications"
                    :value="item.Salesitem.certification_id"
                    class="text-right custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    item-value="Certification.id"
                    item-text="Certification.title"
                    v-if="item.type == 'default'"
                    @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.certification_id',$event)"
                ></v-select>
            </template>
            <template v-slot:item.Salesdescription.species_id="{item}">
                <v-autocomplete
                    :id="item.index + 'species'"
                    :items=allSpecies
                    :placeholder="$t('message.startTypingToSearch')"
                    :value="item.Salesdescription.species_id"
                    clearable
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details="auto"
                    item-value="Species.id"
                    item-text="Species.title"
                    v-if="item.type == 'default'"
                    @input="updatePurchaseOrderItemRowHandle( item.index, 'Salesdescription.species_id',$event)"
                ></v-autocomplete>
            </template>
            <template v-slot:item.Salesdescription.spec_id="{item}">
                <div v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px">{{ $t('message.product') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'product'"
                            :items=allProductGroups
                            :placeholder="item.Salesitem.productgroup_id ? '' : $t('message.required')"
                            :value="item.Salesitem.productgroup_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="ProductGroup.title"
                            item-value="ProductGroup.id"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.productgroup_id',$event)"
                        ></v-autocomplete>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px !important;">{{ $t('message.spec') + ":" }}</div>
                        <v-combobox
                            :id="item.index + 'spec'"
                            :items="allCleanedSpecs"
                            :placeholder="$t('message.selectOptionOrFreeText')"
                            :value="item.Spec.title"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            hide-no-data
                            item-value="Spec.id"
                            item-text="Spec.title"
                            return-object
                            v-if="item.type == 'default'"
                            @focus="currentRowIndex = item.index"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesdescription.spec_id',$event)"
                        ></v-combobox>
                    </div>
                    <div class="d-flex flex-row align-center" v-if="Purchaseorder__Contract__productgroup_id != 15">
                        <div style="width: 50px !important;">{{ $t('message.mc') + ":" }}</div>
                        <v-select
                            :id="item.index + 'mc'"
                            :items="allMoistureContent"
                            :value="item.Salesitem.mc_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Mc.title"
                            item-value="Mc.id"
                            @focus="currentRowIndex = item.index"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.mc_id',$event)"
                        ></v-select>
                    </div>
                    <div class="d-flex flex-row align-center" style="height: 35px">
                        <div style="width: 50px" class="font-weight-bold">{{ $t('message.descr') + ":" }}</div>
                        <div>{{ item.Salesitem.description }}</div>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 50px !important;">{{ $t('message.grade') + ":" }}</div>
                        <v-autocomplete
                            :items="allGrades"
                            :placeholder="$t('message.startTypingToSearch')"
                            :value="item.Salesitem.pograde_id"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details
                            hide-no-data
                            item-text="Grade.title"
                            item-value="Grade.id"
                            @focus="currentRowIndex = item.index"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.pograde_id',$event)"
                        >
                            <template v-slot:selection="{ item }">
                                <div class="text-wrap" style="width: 100%">{{ item.Grade.title }}</div>
                            </template>
                        </v-autocomplete>
                    </div>
                </div>
                <div v-if="item.type == 'othercharge'">
                    <v-text-field
                        :placeholder="$t('message.otherCharges')"
                        :value="item.Salesitem.size"
                        class="custom pa-1 force-text-left bg-lightgrey"
                        clearable
                        dense
                        hide-details
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.size',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.Salesitem.size_id="{item}">
                <div v-if="item.type == 'default'">
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;">{{ $t('message.thickness') + ":" }}</div>
                        <v-autocomplete
                            :id="item.index + 'thickness'"
                            :label="item.Salesitem.thickness_id == null && item.Salesitem.thickness != '' ? item.Salesitem.thickness : ''"
                            :value="item.Salesitem.thickness_id"
                            :items="filteredThickness"
                            class="custom pa-1 bg-lightgrey"
                            clearable
                            dense
                            hide-details="auto"
                            item-text="Thickness.title"
                            item-value="Thickness.id"
                            @focus="currentRowIndex = item.index"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.thickness_id',$event)"
                        ></v-autocomplete>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;">{{ $t('message.width') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'width'"
                            :value="item.Salesitem.width"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.width',$event)"
                        ></v-text-field>
                    </div>
                    <div class="d-flex flex-row align-center">
                        <div style="width: 65px !important;">{{ $t('message.length') + ":" }}</div>
                        <v-text-field
                            :id="item.index + 'length'"
                            :value="item.Salesitem.length"
                            class="custom pa-1 bg-lightgrey force-text-left"
                            clearable
                            dense
                            hide-details="auto"
                            @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.length',$event)"
                        ></v-text-field>
                    </div>
                    <div class="d-flex flex-row align-center" style="min-height: 35px">
                        <div style="min-width: 65px !important;" class="font-weight-bold">{{ $t('message.dim') + ":" }}</div>
                        <div>{{ item.Salesitem.size }}</div>
                    </div>
                </div>
            </template>
            <template v-slot:item.Salesitem.poqty="{item}">
                <div style="height: 100%" v-if="item.type == 'default'">
                    <v-text-field
                        :id="item.index + 'poqty'"
                        :value="item.Salesitem.poqty"
                        class="custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        type="number"
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.poqty',$event)"
                    >
                    </v-text-field>
                    <div class="font-weight-bold font-md d-flex flex-column justify-end" style="height: 34px; font-size: 1.10em">{{ $t('message.unit') }}</div>
                    <v-select
                        :items="uofmQty"
                        :placeholder="$t('message.uofm')"
                        :value="item.Salesitem.pomeasurement_id"
                        class="text-right custom pa-1 bg-lightgrey"
                        dense
                        hide-details
                        item-value="Measurement.id"
                        item-text="Measurement.title"
                        reverse
                        @input="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.pomeasurement_id',$event)"
                        v-if="item.type == 'default'"
                    ></v-select>
                </div>
            </template>
            <template v-slot:item.Salesitem.costprice="{ item }">
                <v-text-field
                    :placeholder="$t('message.price')"
                    :value="item.Salesitem.costprice"
                    class="custom pa-1 bg-lightgrey"
                    dense
                    hide-details
                    type="number"
                    @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.costprice',$event)"
                    v-if="item.type == 'default'"
                >
                </v-text-field>
            </template>
            <template v-slot:item.Salesitem.amount="{ item }">
                <div class="mt-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.poamount,currencyFormatPrecision) }}</div>
                <div class="d-inline-flex" v-if="item.type != 'default'" style="margin-right: -20px">
                    <v-icon small class="mb-3 red--text" v-if="item.type == 'credit'">mdi-minus</v-icon>
                    <v-text-field
                        :placeholder="$t('message.amount')"
                        :value="item.Salesitem.poamount"
                        class="custom pa-1 bg-lightgrey force-text-left"
                        dense
                        hide-details
                        type="number"
                        @change="updatePurchaseOrderItemRowHandle( item.index, 'Salesitem.amount',$event)"
                    >
                    </v-text-field>
                </div>
            </template>
            <template v-slot:item.Salesitem.costprice_converted="{ item }">
                <div class="mt-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.costprice_converted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.poamount_converted="{ item }">
                <div class="mt-3" v-if="item.type == 'default'">{{ formatThisNumber(item.Salesitem.poamount_converted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                <div class="mt-3" v-else>{{ formatThisNumber(item.Salesitem.amount * Purchaseorder__currencyrate, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
            </template>
            <template v-slot:item.Salesitem.itemqty="{item}">
                <div class="mt-3" v-if="item.type == 'default'">{{ item.type != 'credit' ? formatThisNumber(item.Salesitem.itemqty,uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</div>
            </template>
            <template v-slot:item.index="{ item }">
                <div class="ml-3" v-if="[1,2].includes(item.Salesitem.chargeto) || item.type == 'othercharge'">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="deleteItem(item.index, item.Salesitem.id)" v-on="on">
                                <v-icon color="grey">delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('message.deleteThisItem') }}</span>
                    </v-tooltip>
                </div>
                <div v-else class="text-center mt-2 font-weight-bold">{{ $t('message.na') }}</div>
            </template>
            <template v-slot:footer>
                <div class="pt-0 d-flex justify-space-between">
                    <div class="col-4 col-lg-4 col-md-5 pt-5 px-0 d-flex justify-start">
                        <v-btn
                            @click="addOtherChargesItemRow(statePrefix)"
                            color="success"
                            small
                        >{{ $t('message.addOtherCharges') }}
                        </v-btn>
                        <v-btn
                            @click.stop="specNotesDialog = true"
                            class="ml-2"
                            color="success"
                            small
                            v-if="false"
                        >{{ $t('message.addSpecificationNotes') }}
                        </v-btn>
                    </div>
                    <div class="dense-inputs col-8 col-lg-8 col-md-7 pa-0">
                        <v-row justify="end">
                            <v-col cols="2" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalPoQty') }}</div>
                                <div :class="'text-right ' + (itemsTotalQty.length > 1 ? 'font-sm' : 'font-md') + ' font-weight-black d-flex flex-column'">
                                    <span v-for="(total,index) in itemsTotalQty" :class="itemsTotalQty.length > 1 && index == 0  ? 'pt-1' : ''">
                                        {{ formatThisNumber(total.Total.qty,uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                    </span>
                                </div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="px-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.poCurrency') }}</div>
                                <v-container class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="9" offset="3">
                                            <v-select
                                                :items="allCurrencies"
                                                class="custom py-0 mt-0 bg-lightgrey"
                                                hide-details
                                                item-text="Currency.code"
                                                item-value="Currency.id"
                                                reverse
                                                v-model="Purchaseorder__currency_id"
                                            >
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">
                                <div class="text-right font-sm font-weight-black">{{ allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code + ':' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code + ' ' + $t('message.exRate') }}</div>
                                <v-container class="ma-0 py-0 pl-0 pr-0">
                                    <v-row no-gutters>
                                        <v-col cols="10" class="mr-0">
                                            <v-text-field
                                                :value="Purchaseorder__currencyrate"
                                                class="custom pa-1"
                                                dense
                                                type="number"
                                                @change="Purchaseorder__currencyrate = $event"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="2" class="ml-0">
                                            <v-btn
                                                class="ml-2"
                                                height="30px"
                                                text
                                                width="30px"
                                                @click.stop="openForexView = true"
                                            ><v-img
                                                width="25px"
                                                height="25px"
                                                :src="appicUrls.find((url)=>url.key == 'maybankForexRates').icon"
                                            ></v-img>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalCost') + (Purchaseorder__Contract__currency_id != Purchaseorder__currency_id ? '(' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__Contract__currency_id).Currency.code + ')' : '') }}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalAmount, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                            <v-col cols="1" lg="2" md="2" xs="3" class="pl-1 pb-0" v-if="Purchaseorder__Contract__currency_id != Purchaseorder__currency_id">
                                <div class="text-right font-sm font-weight-black">{{ $t('message.totalAmount') + '(' + allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.code +')'}}</div>
                                <div class="text-right font-md font-weight-black">{{ formatThisNumber(itemsTotalAmountConverted, allCurrencies.find((currency)=>currency.Currency.id == Purchaseorder__currency_id).Currency.formatPrecision) }}</div>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
        </v-data-table>
        <ForexView
            :url="appicUrls.find((url)=>url.key == 'maybankForexRates').url"
            namespace="purchaseorder"
        ></ForexView>
        <v-layout row>
            <v-flex xs12 lg6 md6 pl-3 py-1 class="align-center">
                {{ $t('message.specificationNotes') }}
                <v-textarea
                    :value="Purchaseorder__shipremark"
                    class="elevation-0 pt-3"
                    dense
                    hide-details
                    outlined
                    rows="5"
                    @change="Purchaseorder__shipremark = $event"
                ></v-textarea>
            </v-flex>
        </v-layout>
        <v-dialog
            overlay=false
            persistent
            scrollable
            transition="dialog-bottom-transition"
            v-model="specNotesDialog"
            v-if="false"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.specificationNotes') }}</v-card-title>
                <v-card-text class="px-6">
                    <v-textarea
                        :value="Purchaseorder__shipremark"
                        class="elevation-0 pt-3"
                        dense
                        hide-details
                        outlined
                        rows="5"
                        @change="Purchaseorder__shipremark = $event"
                    ></v-textarea>
                </v-card-text>
                <v-card-actions class="px-6">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="specNotesDialog = false"
                        class="ml-3"
                        color="default"
                        small
                    >{{ $t('message.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import {mapFields} from "vuex-map-fields";
    import {generateDimension, generateSpec, numberFormat} from "../../helpers/helpers";
    // import { debounce } from "debounce";
    // import { api } from "../../api";
    import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "../../helpers/vuex-map-fields-prefixed";
    // import ForexView from "./ForexView";

    const ForexView = () => import('./ForexView')

    export default {
        name: "WareHousePurchaseOrderItemList",
        props: ['updateMode'],
        components: {ForexView},
        data() {
            return {
                currentRowIndex: 0,
                dialogs: {
                    confirm:  false,
                    confirm_message: ""
                },
                filteredThickness: [],
                filteredUnits: [],
                specNotesDialog: false
            }
        },
        computed: {
            statePrefix(){
                if(this.updateMode){
                    return 'update'
                } else {
                    return 'current'
                }
            },
            ...mapFieldsPrefixed('purchaseorder', {
                Purchaseorder__Contract__currency_id: 'Purchaseorder.Contract.currency_id',
                Purchaseorder__Contract__is_export: 'Purchaseorder.Contract.is_export',
                Purchaseorder__Contract__ms_id: 'Purchaseorder.Contract.ms_id',
                Purchaseorder__Contract__salestype_id: 'Purchaseorder.Contract.salestype_id',
                Purchaseorder__Contract__productgroup_id: 'Purchaseorder.Contract.productgroup_id',
                Purchaseorder__contract_id: 'Purchaseorder.contract_id',
                Purchaseorder__id: 'Purchaseorder.id',
                Purchaseorder__currency_id: 'Purchaseorder.currency_id',
                Purchaseorder__currencyrate: 'Purchaseorder.currencyrate',
                Purchaseorder__shipremark: 'Purchaseorder.shipremark'
            },'statePrefix'),
            ...mapFields('purchaseorder',{
                newPurchaseOrderSaved: 'newPurchaseOrderSaved',
                newPurchaseOrderUnsaved: 'newPurchaseOrderUnsaved',
                updatedPurchaseOrderUnsaved: 'updatedPurchaseOrderUnsaved',
                updatedPurchaseOrderSaved: 'updatedPurchaseOrderSaved',
                openForexView: 'openForexView'
            }),
            ...mapMultiRowFieldsPrefixed('purchaseorderitem',{
                PurchaseOrderItems: 'PurchaseOrderItems',
            },'statePrefix'),
            ...mapFieldsPrefixed('purchaseorderitem',{
                itemsCurrencyRate: 'itemsCurrencyRate',
                itemsTotalAmount: 'itemsTotalAmount',
                itemsTotalAmountConverted: 'itemsTotalAmountConverted',
                itemsTotalQty: 'itemsTotalQty'
            },'statePrefix'),
            ...mapGetters('certification',{
                allCertifications: 'allCertifications'
            }),
            ...mapGetters('currency',{
                allCurrencies: 'allCurrencies'
            }),
            ...mapGetters('grade',{
                allGrades: 'allGrades',
                findGradeById: 'findGradeById'
            }),
            ...mapGetters('size',{
                allSizes: 'allSizes',
                findSizeById: 'findSizeById'
            }),
            ...mapGetters('spec',{
                allCleanedSpecs: 'allCleanedSpecs',
                allSpecs: 'allSpecs',
                findSpecById: 'findSpecById'
            }),
            ...mapGetters('mc', {
                allMoistureContent: 'allMoistureContent'
            }),
            ...mapGetters('productgroup',{
                allProductGroups: 'allProductGroups'
            }),
            ...mapGetters('species', {
                allSpecies: 'allSpecies',
                findSpeciesById: 'findSpeciesById'
            }),
            ...mapGetters('thickness', {
                allThickness: 'allThickness'
            }),
            ...mapGetters([
                'appicUrls',
                // 'certificationsNew',
                'containerSizes',
                'currencies',
                // 'productGroupsNew',
                'uofmQty'
            ]),
            currencyFormatPrecision () {
                return this.allCurrencies.find((currency) => currency.Currency.id === this.Purchaseorder__currency_id)?.Currency?.formatPrecision
            },
            headers() {
                let headers = [
                    {
                        id: 0,
                        text: this.$t('message.lineNo'),
                        value: 'lineNumber',
                    },
                    {
                        id: 1,
                        text: this.$t('message.certification'),
                        value: 'Salesitem.certification_id',
                    },
                    {
                        id: 1,
                        text: this.$t('message.species'),
                        value: 'Salesdescription.species_id',
                    },
                    {
                        id: 2,
                        text: this.$t('message.description'),
                        value: 'Salesdescription.spec_id',
                    },
                    {
                        id: 3,
                        text: this.$t('message.size'),
                        value: 'Salesitem.size_id',
                    },
                    {
                        id: 5,
                        text: this.$t('message.poQuantity'),
                        value: 'Salesitem.poqty',
                    },
                    {
                        id: 7,
                        text: this.$t('message.costPrice'),
                        value: 'Salesitem.costprice',
                    },
                    {
                        id: 7,
                        text: this.$t('message.cost'),
                        value: 'Salesitem.amount',
                    }
                ]
                if(this.Purchaseorder__Contract__currency_id != this.Purchaseorder__currency_id) {
                    headers.push(
                        {
                            id: 8,
                            text: this.$t('message.costPrice'),
                            value: 'Salesitem.costprice_converted',
                        }
                    )
                    headers.push(
                        {
                            id: 9,
                            text: this.$t('message.costAmount'),
                            value: 'Salesitem.poamount_converted',
                        }
                    )
                    headers.push(
                        {
                            id: 10,
                            text: this.$t('message.contractQuantity'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            id: 11,
                            text: this.$t('message.actions'),
                            value: 'index',
                        }
                    )
                } else {
                    headers.push(
                        {
                            id: 9,
                            text: this.$t('message.contractQuantity'),
                            value: 'Salesitem.itemqty',
                        }
                    )
                    headers.push(
                        {
                            id: 10,
                            text: this.$t('message.actions'),
                            value: 'index',
                        }
                    )
                }
                return headers
            }
        },
        methods: {
            ...mapActions('certification', {
                getAllCertifications: 'getAllCertifications'
            }),
            ...mapActions('currency', {
                getAllCurrencies: 'getAllCurrencies'
            }),
            ...mapActions('grade', {
                getAllGrades: 'getAllGrades'
            }),
            ...mapActions('mc', {
                getAllMoistureContent: 'getAllMoistureContent'
            }),
            ...mapActions('productgroup', {
                getAllProductGroups: 'getAllProductGroups'
            }),
            ...mapActions('purchaseorderitem',{
                convertPriceAndAmount: 'convertPriceAndAmount',
                deletePurchaseOrderItem: 'deletePurchaseOrderItem',
                updateTotals: 'updateTotals'
            }),
            ...mapActions('size', {
                getAllSizes: 'getAllSizes'
            }),
            ...mapActions('spec', {
                getAllCleanedSpecs: 'getAllCleanedSpecs',
                getAllSpecs: 'getAllSpecs'
            }),
            ...mapActions('species', {
                getAllSpecies: 'getAllSpecies'
            }),
            ...mapActions('thickness', {
                getAllThickness: 'getAllThickness'
            }),
            ...mapMutations('purchaseorderitem',{
                addOtherChargesItemRow: 'addOtherChargesItemRow',
                deletePurchaseOrderItemRow: 'deletePurchaseOrderItemRow'
            }),
            async deleteItem(itemIndex,itemId) {
                let _this = this
                if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                    let promise = new Promise((resolve, reject) => {
                        let payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix
                        }
                        this.deletePurchaseOrderItemRow(payload)
                        resolve('ok')
                    });
                    promise.then(()=>{
                        if(itemId != null){
                            this.deletePurchaseOrderItem({
                                contract_id: this.Purchaseorder__contract_id,
                                salesitem_id: itemId}
                            )
                            .then((response)=>{
                                _this.updateTotals(_this.statePrefix)
                            })
                        } else {
                            _this.updateTotals(_this.statePrefix)
                        }
                    })
                    .finally(()=>{
                        _this.updateTotals(_this.statePrefix)
                    })
                }
            },
            filterThickness(ms) {
                let various = this.allThickness.find(t => {
                    return t.Thickness.ms == ms && t.Thickness.title == 'various'
                })
                let thicknesses = this.allThickness.filter(t => {
                    return t.Thickness.ms == ms && t.Thickness.title != 'various'
                })
                if(various){
                    this.filteredThickness = [various,...thicknesses]
                } else {
                    this.filteredThickness = thicknesses
                }
            },
            formatThisNumber (value,format) {
                return numberFormat(value,format)
            },
            getCertification(certificationId) {
                let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
                if(certificationObj){
                    return certificationObj.Certification.title
                }
                return ''
            },
            getCurrency(currencyId) {
                let currencyObject = this.allCurrencies.find(c => c.Currency.id == currencyId)
                if(currencyObject){
                    return currencyObject.Currency.code
                }
                return ''
            },
            getProductGroup(productGroupId){
                let productGroupObj = this.allProductGroups.find(pg => pg.Productgroup?.id != null && pg.Productgroup.id == productGroupId)
                if(productGroupObj){
                    return productGroupObj.Productgroup.title
                }
                return ''
            },
            setItemDescription(idx) {
                if(idx != null) {
                    let product = null
                    let spec = null
                    let mc = null

                    let productGroupId = this.PurchaseOrderItems[idx].Salesitem.productgroup_id
                    let specId = this.PurchaseOrderItems[idx].Salesdescription.spec_id
                    let mcId = this.PurchaseOrderItems[idx].Salesitem.mc_id

                    let productObj = this.allProductGroups.find(pg => pg.ProductGroup?.id == productGroupId)
                    let specObj = this.allCleanedSpecs.find(s => s.Spec.id == specId)
                    if(specId == 'NEW'){
                        specObj = { Spec: this.PurchaseOrderItems[idx]['Spec'] }
                    }
                    if(typeof specObj === 'undefined'){
                        specObj = { Spec: this.PurchaseOrderItems[idx]['Spec'] }
                    }
                    let mcObj = this.allMoistureContent.find(mc => mc.Mc.id == mcId)

                    if(productObj) product = productObj?.Productgroup?.title
                    if(specObj) spec = specObj?.Spec?.title
                    if(mcObj) mc = mcObj?.Mc?.title

                    let description = generateSpec(product, spec, mc)
                    this.PurchaseOrderItems[idx].Salesitem.description = description
                }
            },
            updatePurchaseOrderItemRowHandle(idx,field,value) {
                switch(field) {
                    case 'Salesdescription.spec_id':
                        if(value) {
                            if(value?.Spec?.id) { //value is an object
                                var payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.id
                                }
                                // assign Spec property in Salesdescription
                                this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                    index: idx,
                                    field: 'Spec.id',
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.id
                                })
                                this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                    index: idx,
                                    field: 'Spec.title',
                                    prefix: this.statePrefix,
                                    value: value?.Spec?.title
                                })
                            } else { //value is a string
                                var payload = {
                                    index: idx,
                                    field: field,
                                    prefix: this.statePrefix,
                                    value: 'NEW'
                                }
                                // assign Spec property in Salesdescription
                                this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                    index: idx,
                                    field: 'Spec.id',
                                    prefix: this.statePrefix,
                                    value: 'NEW'
                                })
                                this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                    index: idx,
                                    field: 'Spec.title',
                                    prefix: this.statePrefix,
                                    value: value
                                })
                            }
                        } else {
                            var payload = {
                                index: idx,
                                field: field,
                                prefix: this.statePrefix,
                                value: null
                            }
                            // assign Spec property in Salesdescription
                            this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                index: idx,
                                field: 'Spec.id',
                                prefix: this.statePrefix,
                                value: null
                            })
                            this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                index: idx,
                                field: 'Spec.title',
                                prefix: this.statePrefix,
                                value: null
                            })
                        }
                        break;
                    case 'Salesitem.size_id':
                        break;
                    case 'Salesitem.grade_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        }
                        // assign Grade property in Salesitem
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Grade.id',
                            prefix: this.statePrefix,
                            value: value?.Grade?.id
                        })
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Grade.title',
                            prefix: this.statePrefix,
                            value: value?.Grade?.title
                        })
                        break;
                    case 'Salesdescription.species_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        //assign Species property in
                        let species = this.findSpeciesById(value);
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Species.id',
                            prefix: this.statePrefix,
                            value: species?.Species?.id ? species.Species.id : null
                        })
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Species.title',
                            prefix: this.statePrefix,
                            value: species?.Species?.title ? species.Species.title : null
                        })
                        break;
                    case 'Salesitem.measurement_id':
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        //assign Measurement property in
                        let measurement = this.uofmQty.filter(uofm => uofm.Measurement.id === value)[0];
                        if(measurement != null) {
                            this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                index: idx,
                                field: 'Measurement.id',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.id != null ? measurement.Measurement.id : null
                            })
                            this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                                index: idx,
                                field: 'Measurement.title',
                                prefix: this.statePrefix,
                                value: measurement.Measurement.title != null ? measurement.Measurement.title : null
                            })
                        }
                        break;
                    case 'Salesitem.thickness_id':
                    case 'Salesitem.width':
                    case 'Salesitem.length':
                        //update size_text
                        let thicknessId = this.PurchaseOrderItems[idx].Salesitem.thickness_id
                        let width = this.PurchaseOrderItems[idx].Salesitem.width
                        let length = this.PurchaseOrderItems[idx].Salesitem.length
                        if(field == 'Salesitem.thickness_id'){
                            thicknessId = value
                        }
                        if(field == 'Salesitem.width'){
                            width = value
                        }
                        if(field == 'Salesitem.length'){
                            length = value
                        }

                        let thicknessObj = this.allThickness.find(t => t.Thickness.id == thicknessId)
                        let thickness = thicknessObj?.Thickness.title

                        let sizeText = generateDimension(thickness,width,length)

                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Salesitem.size',
                            prefix: this.statePrefix,
                            value: sizeText
                        })

                        //update Size object
                        //check if size exists
                        let sizeId = null
                        let sizeObj = this.allSizes.find(s => s.Size.title == sizeText)
                        if(sizeObj){
                            sizeId = sizeObj?.Size.id
                        } else {
                            if (sizeText.length > 0) {
                                sizeId = 'NEW' //new size object
                            }
                        }
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Size.id',
                            prefix: this.statePrefix,
                            value: sizeId
                        })
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Size.title',
                            prefix: this.statePrefix,
                            value: sizeText
                        })
                        this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', {
                            index: idx,
                            field: 'Salesitem.size_id',
                            prefix: this.statePrefix,
                            value: sizeId
                        })
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                        break
                    default:
                        if(typeof value === 'undefined') value = null
                        var payload = {
                            index: idx,
                            field: field,
                            prefix: this.statePrefix,
                            value: value,
                        }
                }
                let promise = new Promise((resolve, reject) => {
                    this.$store.commit('purchaseorderitem/updatePurchaseOrderItems', payload)
                    resolve('ok')
                });

                promise
                    .then(()=>{
                        if(['Salesitem.productgroup_id','Salesdescription.spec_id','Salesitem.grade_id','Salesitem.mc_id'].includes(field)){
                            this.setItemDescription(idx)
                        }
                        if(['Salesitem.costprice','Salesitem.poamount','Salesitem.poqty'].includes(field)) {
                            this.updateTotals(this.statePrefix)
                        }
                    })
            },
            uofmPrecision (uofm) {
                if(uofm) {
                    return this.uofmQty.find((u) => u.Measurement.title == uofm.trim()).Measurement.formatPrecision
                } else {
                    return '0,0.00'
                }
            },
        },
        watch: {
            Purchaseorder__Contract__ms_id(value){
                // this.filteredUnits = this.uofmQty.filter(u => {
                //     return u.Measurement.system == value || u.Measurement.system == null
                // })
                // this.filteredThickness = this.allThickness.filter(t => {
                //     return t.Thickness.ms == value
                // })
                this.filterThickness(value)
            },
            Purchaseorder__currencyrate (value) {
                this.itemsCurrencyRate = value
                this.convertPriceAndAmount(this.statePrefix)
            }
        },
        mounted(){
            if(this.allCleanedSpecs.length == 0) this.getAllCleanedSpecs()
            if(this.allGrades.length == 0) this.getAllGrades()
            if(this.allMoistureContent.length == 0) this.getAllMoistureContent()
            if(this.allProductGroups.length == 0) this.getAllProductGroups()
            if(this.allSizes.length == 0) this.getAllSizes()
            if(this.allSpecs.length == 0) this.getAllSpecs()
            if(this.allSpecies.length == 0) this.getAllSpecies()
            if(this.allThickness.length == 0) this.getAllThickness()
            if(this.allCertifications.length == 0) this.getAllCertifications()
            if(this.allCurrencies.length == 0) this.getAllCurrencies()

            // this.filteredThickness = this.allThickness.filter(t => {
            //     return t.Thickness.ms == this.Purchaseorder__Contract__ms_id
            // })

            this.filterThickness(this.Purchaseorder__Contract__ms_id)

            this.$store.watch(
                function(state) {
                    return state.appic.purchaseorderitem.update.PurchaseOrderItems
                },
                () => {
                    this.updatedPurchaseOrderUnsaved = true
                },
                { deep: true }
            )
            this.$store.watch(
                function(state) {
                    return state.appic.purchaseorderitem.current.PurchaseOrderItems
                },
                () => {
                    this.newPurchaseOrderUnsaved = true
                },
                { deep: true }
            )
            this.openForexView = false;
        }
    }
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .force-text-left {
        text-align: left !important;
    }
    .v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-input__append-inner {
        margin-left: 0px !important;
    }
    .v-data-table tbody td {
        vertical-align: top !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 40px !important;
    }
</style>